import { JobAutoFormTypeCosmicData } from '@finn/ui-cosmic';
import { interactionTrackingEvent, TrackingEventName } from '@finn/ui-modules';
import { formatPhoneNumber } from '@finn/ui-utils';
import { Snackbar } from '@material-ui/core';
import { Formik, FormikHelpers } from 'formik';
import { useRouter } from 'next/router';
import React, { useState } from 'react';

import { useCurrentLocale } from '~/hooks/useCurrentLocale';
import { UIBaseCosmicObject } from '~/types/UIModules';

import Alert from '../Alert';
import {
  createValidationSchema,
  formatFormDataToIntegromatPayload,
  FormValues,
  initialValues,
  submitJobAutoContactForm,
} from './data';
import Form from './Form';

type Props = {
  cosmicData: UIBaseCosmicObject<JobAutoFormTypeCosmicData>;
};

const FormikWrapper: React.FunctionComponent<Props> = ({ cosmicData }) => {
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const { region } = useCurrentLocale();
  const router = useRouter();

  const handleSubmit = async (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => {
    const formData = {
      ...values,
      phoneNumber: formatPhoneNumber(values.phoneNumber, region),
    };

    setLoading(true);
    try {
      const { status } = await submitJobAutoContactForm(
        formatFormDataToIntegromatPayload(formData)
      );
      if (status >= 200 && status < 300) {
        setSuccessMessage(cosmicData.metadata.submit_success_message);
        setLoading(false);
        interactionTrackingEvent(TrackingEventName.JOB_AUTO_FORM_SUBMITTED, {
          url: router.pathname,
        });
        formikHelpers.resetForm();
        setTimeout(() => {
          setSuccessMessage(null);
        }, 3000);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const validationSchema = createValidationSchema({ region });

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {(formik) => {
        return (
          <>
            <Form formik={formik} loading={loading} cosmicData={cosmicData} />
            <Snackbar
              open={Boolean(successMessage)}
              autoHideDuration={3000}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <Alert severity="success">{successMessage}</Alert>
            </Snackbar>
          </>
        );
      }}
    </Formik>
  );
};
export default FormikWrapper;
