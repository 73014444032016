import { Container } from '@finn/atoms';
import { JobAutoFormCosmicMetadata } from '@finn/ui-cosmic';
import { cn } from '@finn/ui-utils';
import { Box, makeStyles } from '@material-ui/core';
import React from 'react';

import { parseToHtml } from '~/utils/html';

import { titleHeading } from '../B2BContactForm/B2BContactForm';
import FormikWrapper from './FormikWrapper';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'grid',
    gridGap: theme.spacing(3),
    gridTemplateColumns: '1fr',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
  title: {
    marginBottom: theme.spacing(7),
  },
}));

interface IProps {
  data: JobAutoFormCosmicMetadata;
}

export const JobAutoContactForm: React.FunctionComponent<IProps> = ({
  data,
}) => {
  const classes = useStyles();
  const titleClassName = titleHeading[data.header_size?.key] || titleHeading.h2;
  const TitleEl =
    (data.header_size?.key as keyof JSX.IntrinsicElements) || 'h2';

  return (
    <Container>
      <Box id="jobauto-contact-form" className={classes.wrapper}>
        <Box>
          <TitleEl className={cn(titleClassName, 'mb-14')}>
            {parseToHtml(data.title)}
          </TitleEl>
          <p className="body-16-light">{parseToHtml(data.description)}</p>
        </Box>
        <Box>
          <FormikWrapper cosmicData={data.form_type} />
        </Box>
      </Box>
    </Container>
  );
};

export default JobAutoContactForm;
